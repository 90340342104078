* {
  outline: none !important;
}

html {
  font-size: 62.5%;
  /*font-family: Helvetica Neue, Arial, sans-serif;*/
  font-family: Muli, Roboto, 'Helvetica', Arial, sans-serif !important;
  background-color: #262933;
}

body {
  font-size: 14px;
  line-height: 1.4;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
  z-index: 99;
}

a[role='button'] {
  text-decoration: none;
}

[role='tooltip'] {
  z-index: 999;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:active {
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
    border-radius: 20px;
  }
}

@-moz-document url-prefix() {
  .firefox-height-react-table {
    max-height: calc(100vh - 200px);
  }
}

@-moz-document url-prefix() {
  .firefox-height-react-table-no-toolbar {
    max-height: calc(100vh - 100px);
  }
}

form label {
  z-index: 99;
}

.MuiTooltip-popper {
  z-index: 5000;
}

.live-panel-users-table .-headerGroups {
  display: none;
}

.aggregated:hover {
  background: #d2d3d5 !important;
}

.live-panel-users-table .rt-expander,
.live-panel-users-table .rt-expandable,
.live-panel-users-table .rt-expandable .rt-pivot {
  display: none !important;
}

.contact .MuiChip-avatar {
  background: #2d323e !important;
  color: #fff !important;
}

.me .MuiChip-avatar {
  background: #cdcdcd !important;
}

.contact .MuiChip-label {
  color: #fff !important;
}

.audioChip.contact {
  background-color: #3c4252;
}

.app-bar {
  background-color: white !important;
  color: black;
}

.break {
  flex-basis: 100%;
  height: 0;
}

div[class^='MuiPickersCalendarHeader'] > button[class^='MuiButtonBase'] {
  color: black !important;
}

#simple-popper {
  z-index: 9999;
}

.selected--flag--option {
  padding: 0 !important;
}

div[role='tooltip'] {
  z-index: 19999;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.ReactTable {
  -webkit-overflow-scrolling: touch;
  border: 0;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.header-colored {
  padding: 10px 0;
  background-color: #3b4150;
  border-radius: 10px;
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 20px;
}

.box-shadow-main {
  -webkit-box-shadow: 2px 1px 30px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 1px 30px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 1px 30px -10px rgba(0, 0, 0, 0.75);
}
